import React from 'react';
import PropTypes from 'prop-types';
import styles from './Body.module.scss';
import Row from '../Row/Row';

const Body = ({ data }) => (
  <div className={styles.Body}>
    {
      data?.map((item, index) => (
        <Row data={item} key={index} />
      ))
    }
  </div>
);

Body.propTypes = {};

Body.defaultProps = {};

export default Body;
