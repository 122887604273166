import React, { useState } from "react";
import styles from "./Row.module.scss";
import { IonRow } from "@ionic/react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";

const Row = ({
  display,
  data,
  type,
  setDeleteBtn = {},
  setEditBtn = {},
  editData = {},
  setEditData = {},
}) => {
  const editUser = (item) => {
    setEditData(item);
  };
  return (
    <div
      className={styles.Row}
      style={{
        backgroundColor: type === "heading" ? "#F5F5F5" : "transparent",
        opacity: type === "heading" ? "1" : "0.5",
      }}
    >
      {/* <IonRow>
        <div style={{ width: `${100 / 6}%` }}>
          <div className={styles.Col}>
            {display === "user"
              ? type === "heading"
                ? data[0]?.content
                : data[6]?.content
              : data?.}
          </div>
        </div>
        <div style={{ width: `${100 / 6}%` }}>
          <div className={styles.Col}>
            {display === "user" ? data[1].content : data[1].content}
          </div>
        </div>
        <div style={{ width: `${100 / 6}%` }}>
          <div className={styles.Col}>
            {display === "user"
              ? type === "heading"
                ? data[2]?.content
                : data[4]?.content
              : data[2].content}
          </div>
        </div>
        <div style={{ width: `${100 / 6}%` }}>
          <div className={styles.Col}>
            {display === "user"
              ? type === "heading"
                ? data[3]?.content
                : data[4]?.content
              : data[3].content}
          </div>
        </div>
        <div style={{ width: `${100 / 6}%` }}>
          <div className={styles.Col}>
            {display === "user"
              ? type === "heading"
                ? data[4]?.content
                : data[5]?.content
              : data[4].content}
          </div>
        </div>
        <div style={{ width: `${100 / 6}%` }} className={styles.Col}>
          {type != "heading" ? (
            <div className={styles.Icons}>
              <FaRegEdit
                color="#F85B4D"
                size="1.4rem"
                onClick={() => {
                  setEditBtn(true);
                  editUser(data);
                }}
                style={{ cursor: "pointer" }}
              />
              <RiDeleteBin5Line
                color=" #F85B4D"
                size="1.4rem"
                onClick={() => {
                  setDeleteBtn(true);
                  editUser(data);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : (
            "Actions"
          )}
        </div>
      </IonRow> */}
    </div>
  );
};

Row.propTypes = {};

Row.defaultProps = {};

export default Row;
