import { SET_AUTH_DATA, LOGOUT_USER } from "store/types/AuthData"

export const setAuthData = (authData) => {
    return {
        type: SET_AUTH_DATA,
        authData
    }
}

export const logOut = () => {
    return {
        type: LOGOUT_USER,
    }
}