import React from 'react';
import styles from './Button.module.scss';
import { TfiPlus } from "react-icons/tfi";

const Button = ({ width, text, onClick, type }) => {

  return (

    <div className={styles.Container}>
      <button onClick={onClick}>{width < 768 ? type === "device" ? (<TfiPlus size={25} />) : type === "users" ? (<TfiPlus size={25} />) : " " : text}</button>
    </div>
  )
};

Button.propTypes = {};

Button.defaultProps = {};

export default Button;
