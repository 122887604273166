import { IonPage, IonContent } from "@ionic/react";
import React, { useState } from "react";
import styles from "./Login.module.scss";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { loginUser } from "./../../services/api/auth";
import { setItem } from "helpers/miscellaneous";
import { useDispatch } from "react-redux";
import { setAuthData } from "store/actions/AuthData";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState();
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const addData = (e) => {
    const copyData = { ...data };
    copyData[e.target.id] = e.target.value;
    setData(copyData);
  };
  const submitData = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(data);
      if (response.status === 200) {
        await setItem("auth", response.data);
        await setItem("time", new Date());
        dispatch(setAuthData(response.data));
        history.replace("/dashboard/home");
      }
    } catch (error) {
      if (error.response.status === 401) {
        // setError(error.response.data);
      }
      if (error.response.status === 402) {
        // console.log(error.response.data);
      }
    }
  };
  return (
    <IonPage>
      <IonContent>
        <div className={styles.Login}>
          <div className={styles.Container}>
            <div className={styles.FormWrap}>
              <div className={styles.HeaderWrap}>
                <div className={styles.LogoWrap}>
                  <img
                    className={styles.CdacLogo}
                    src="/assets/icon/menu/logo1.png"
                    alt=""
                  />

                  <img
                    className={styles.AgriLogo}
                    src="/assets/icon/menu/logo2.png"
                    alt=""
                  />
                  <img
                    className={styles.DprLogo}
                    src="/assets/icon/menu/logo3.png"
                    alt=""
                  />
                </div>

                <p className={styles.Title}>Smart poultry</p>
              </div>

              <form>
                <label>
                  <input
                    id="username"
                    type="text"
                    name="name"
                    placeholder="Enter username"
                    onChange={(e) => {
                      addData(e);
                    }}
                  />
                </label>
                <label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    onChange={(e) => {
                      addData(e);
                    }}
                  />
                </label>

                <p className={styles.Password}>Forgot password?</p>
              </form>
              <div className={styles.Button}>
                <Button
                  onClick={(e) => {
                    submitData(e);
                  }}
                >
                  Sign in
                </Button>
              </div>
            </div>
            {
              error ? <p className={styles.ErrorWrap}>
                {error}
              </p> : ""
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
