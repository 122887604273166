import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import AppData from './reducers/AppData';
import AuthData from './reducers/AuthData';

/*
 * combining all the reducers
 */
const rootReducer = combineReducers({
    appData: AppData,
    authData: AuthData,
});

/*
 * composeEnhancers is for chrome dev toolbar which helps us see store management of redux
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/*
 * Creating Store and applying middleware
 */
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));


export default store;
