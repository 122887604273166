import {
    SET_USERS, SET_DEVICES, SET_SELECTED_DEVICE, SET_SELECTED_TIME, SET_SENSORS_DATA, SET_ACTIVE_DEVICES, SET_SELECTED_DEVICES, SET_FARMS, SET_SELECTED_FARM, SET_JUMP, SET_JUMP_TIME
} from "store/types/AppData";

export const setUsers = (users) => {
    return {
        type: SET_USERS,
        users
    };
};

export const setDevices = (devices) => {
    return {
        type: SET_DEVICES,
        devices
    };
};

export const setSelectedDevice = (selectedDevice) => {
    return {
        type: SET_SELECTED_DEVICE,
        selectedDevice
    };
};

export const setSelectedTime = (selectedTime) => {
    return {
        type: SET_SELECTED_TIME,
        selectedTime
    };
};

export const setSensorsData = (sensorsData) => {
    return {
        type: SET_SENSORS_DATA,
        sensorsData
    };
};
export const setActiveDevices = (activeDevices) => {
    return {
        type: SET_ACTIVE_DEVICES,
        activeDevices
    };
};

export const setDevicesBasedOnLocation = (location) => {
    // console.log(location);
    return {
        type: SET_SELECTED_DEVICES,
        location
    };
};

export const setFarms = (farms) => {
    // console.log(location);
    return {
        type: SET_FARMS,
        farms
    };
};

export const setJumpTime = (jumpTime) => {
    // console.log(location);
    return {
        type: SET_JUMP_TIME,
        jumpTime
    };
};

export const setJump = (jump) => {
    // console.log(location);
    return {
        type: SET_JUMP,
        jump
    };
};

export const setSelectedFarm = (farm) => {
    return {
        type: SET_SELECTED_FARM,
        farm
    };
};
