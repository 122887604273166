import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DashboardLayout.module.scss';

import { Route, useLocation } from "react-router";
import Routes from './Routes';
import Common from 'components/Common/Common';
import { getAllDevices } from 'services/api/devices';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDevices, setDevices, setSelectedDevice, setSelectedTime, setSensorsData, setDevicesBasedOnLocation, setFarms, setSelectedFarm, setJump, setJumpTime } from 'store/actions/AppData';
import { emitActiveDevices, requestDataFromTime, requestLiveData, requestSensorData } from 'helpers/sockets';
import useSensorData from 'hooks/useSensorData';
import useLiveData from 'hooks/useLiveData';
import useActiveDevices from 'hooks/useActiveDevices';

const DashboardLayout = ({ isAdmin }) => {
  const pages = Routes(isAdmin)

  const location = useLocation();
  const [pageTitle, setpageTitle] = useState('');

  const { devices, selectedDevice, selectedTime, sensorsData, activeDevices, selectedFarm, selectedDevices, jump, jumpTime } = useSelector(state => state.appData)

  const sensorData = useSensorData();
  const liveData = useLiveData();
  const dispatch = useDispatch()

  const updatedData = () => {
    getAllDevices()
      .then((res) => {
        if (res.status == 200) {

          let _devices = res.data
          _devices.forEach(d => {
            d.status = activeDevices.includes(d.deviceId) ? 'Online' : 'Offline'
            return d;
          });
          dispatch(setDevices(_devices));
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  useEffect(() => {
    emitActiveDevices();
  }, [location])

  useEffect(() => {
    updatedData();
    dispatch(setJumpTime({ label: "Minutes", value: "min", req: 'min' }))
  }, [])
  useEffect(() => {
    dispatch(setSensorsData(sensorData))
  }, [sensorData])

  // useEffect(() => {
  //   if (devices?.[0]?.location) {
  //     dispatch(setDevicesBasedOnLocation(devices?.[0]?.location));
  //     const farms = devices?.map(device => device?.location);
  //     dispatch(setFarms([...new Set([...farms, 'All'])]));
  //     dispatch(setSelectedFarm({ label: [...new Set(farms)]?.[0], value: [...new Set(farms)]?.[0] }))
  //   }
  // }, [devices])

  useEffect(() => {
    let _devices = JSON.parse(JSON.stringify(devices));
    _devices.forEach(d => {
      d.status = activeDevices.includes(d.deviceId) ? 'Online' : 'Offline'
      return d;
    });
    dispatch(setDevices(_devices));
  }, [activeDevices])

  useEffect(() => {
    if (selectedTime?.req !== 'date') {
      sensorsData.splice(0, 1)
      let _sensorData = [...sensorsData, liveData]
      dispatch(setSensorsData(_sensorData))
    }
  }, [liveData])

  useEffect(() => {
    if (selectedDevice && selectedTime && jumpTime) {
      if (!selectedTime.req === 'date' && !selectedTime?.from_date && !selectedTime?.to_date) {
        return;
      }
      requestSensorData({
        deviceId: selectedDevice?.value,
        from_time: new Date(selectedTime?.from_date).valueOf() || '',
        to_time: new Date(selectedTime?.to_date).valueOf() || '',
        by: selectedTime?.req || '',
        jump: jump ? jump : 0,
        jumpTime: jumpTime?.req ? jumpTime?.req : 'min'
      })
    }
  }, [selectedDevice, selectedTime, jumpTime, jump])

  useEffect(() => {
    if (devices.length > 0) {
      if (!selectedDevice)
        dispatch(setSelectedDevice({
          label: devices[0].deviceId,
          value: devices[0].deviceId,
          item: devices[0]
        }))

      if (!selectedTime)
        dispatch(setSelectedTime({ label: "Live(Last 15min)", value: "15min", req: 'all' }))
      if (selectedDevices.length <= 0)
        dispatch(setDevicesBasedOnLocation(devices?.[0]?.location));
      const farms = devices?.map(device => device?.location);
      dispatch(setFarms([...new Set([...farms, 'All'])]));
      if (selectedFarm?.length <= 0)
        dispatch(setSelectedFarm({ label: [...new Set(farms)]?.[0], value: [...new Set(farms)]?.[0] }))

    }
  }, [devices])

  useEffect(() => {

    pages.filter(i => location.pathname == i.link ? setpageTitle(i.name) : '')
    return () => {
      setpageTitle('');
    }

  }, [location])

  return (
    <div className={styles.DashboardLayout}>
      <Common menu={pages.map(({ link, icon, name }) => ({ link, icon, name }))} heading={pageTitle}>
        {pages.map(({ link, component }, i) => (
          <Route exact path={link} key={i} render={(props) => component}></Route>
        ))}
      </Common>
    </div>
  )
};

DashboardLayout.propTypes = {};

DashboardLayout.defaultProps = {};

export default DashboardLayout;
