import React, { useState, useEffect } from 'react';
import styles from "./LineChart.module.scss";
import 'chartjs-adapter-moment'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Animation,
  TimeSeriesScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  TimeSeriesScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

export default function LineChart({ values, param, labels, setGraphLoading }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },

    animation: {
      onProgress: function (animation) {
        // let val = animation.currentStep / animation.numSteps;
        // console.log(animation, val);
      },
      onComplete: function (animation) {
        // console.log(animation);

      }
    },
    scales: {
      x: {
        type: 'timeseries',
      }
    }
  };
  const data = {
    labels,
    datasets: [
      {
        label: param,
        data: values.map((item) => item),
        borderWidth: 1,
        pointRadius: 0,
        borderColor: 'rgb(0, 255, 100)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        spanGaps: true,
      },
    ],
  };

  return (
    <div

      style={{ width: "100%" }}>

      <Line
        id={param.replace(" ", "")}
        className={styles.Line} options={
          options
        } data={data} />
    </div>
  );
}
