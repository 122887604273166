import { downloadData } from "helpers/sockets";
import { useEffect, useState } from "react";

const useDownloadData = (props) => {
 const [liveData, setLiveData] = useState(null);
 useEffect(() => {
  downloadData((err, response) => {
   if (err) return;
   setLiveData(response);
  });
 }, []);
 return liveData;
};

export default useDownloadData;
