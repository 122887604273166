
import styles from "./ItemDetails.module.scss";

const ItemDetails = ({
  num,
  onDelete,
  data,
  onEdit,
}) => {
  return (
    <div className={styles.MainContainer}>
      <div className={styles.Container}>
        <div className={styles.TableWrap}>
          <div className={`${styles.ItemList} ${styles.Single}`}>
            <p className={styles.Title}>
              Sr. No.
            </p>
            <p className={styles.Desc}>
              {num}
            </p>
          </div>
        </div>

        <div className={styles.TableWrap}>
          {
            Object.keys(data).map((item, index) => (
              <div className={styles.ItemList} key={index}>
                <p className={styles.Title}>{item}</p>
                <p className={styles.Desc}>{data[item]}</p>
              </div>
            ))
          }
        </div>
        <div className={styles.BtnContainer}>
          <button
            onClick={onEdit}
            className={styles.EditButton}
          >
            Edit
          </button>
          <button
            onClick={onDelete}
            className={styles.DeleteButton}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
export default ItemDetails;
