export const DeviceFields = () => [
    {
        type: "text",
        label: "Device ID",
        id: "deviceId",
        required: true,
        value: ''
    },
    {
        type: "text",
        label: "Username",
        id: "username",
        required: true,
        value: ''
    },
    {
        type: "text",
        label: "Password",
        id: "password",
        required: true,
        value: ''
    },
    {
        type: "text",
        label: "Device Group",
        id: "location",
        required: true,
        value: ''
    },
    {
        type: "number",
        label: "Data Interval (in minutes)",
        id: "interval",
        required: true,
        value: ""
    },
];

const Roles = [
    { label: "Admin", value: "true" },
    { label: "Not Admin", value: "false" },
];

export const UserFields = () => [
    {
        type: "text",
        label: "Name",
        id: "name",
        required: true,
        value: ""
    },
    {
        type: "text",
        label: "Password",
        id: "password",
        required: true,
        value: ""
    },
    {
        type: "text",
        label: "Username",
        id: "username",
        required: true,
        value: ""
    },
    {
        type: "text",
        label: "Email ID",
        id: "email",
        required: true,
        value: ""
    },
    {
        type: "select",
        label: "User Type",
        id: "type",
        required: true,
        value: "",
        options: Roles
    },
    {
        type: "text",
        label: "Location",
        id: "location",
        required: true,
        value: ""
    },
];