import React, { useState, useEffect } from "react";
import styles from "./Devices.module.scss";
import Button from "../../components/Button/Button";
import OptionsAccordion from "../../components/OptionsAccordion/OptionsAccordion";
import { useDispatch } from "react-redux";
import {
  addNewDevice,
  deleteDevice,
  editDevice,
  getAllDevices,
} from "./../../services/api/devices";
import { useSelector } from "react-redux";
import Pages from "../../components/NavMenu/CurserMenu/Pages";
import useWindowSize from "hooks/useWindowSize";
import Table from "components/Table/Table";
import Form from "components/Forms/Form/Form";
import { DeviceFields } from "helpers/FormFields";
import { setDevices } from "store/actions/AppData";
import { getFormFieldsWithValue } from "helpers/FormEdit";
import Actions from "components/Table/Actions/Actions";
import DeleteHandler from "components/Forms/DeleteHandler/DeleteHandler";
import ItemDetails from "components/ItemDetails/ItemDetails";
import ItemsList from "components/ItemsList/ItemsList";
import Footer from "components/Footer/Footer";
import { setSelectedFarm } from "store/actions/AppData";
import { setDevicesBasedOnLocation } from "store/actions/AppData";

const Devices = () => {
  const options = [{ label: 'All', value: '' }, { label: 'Online', value: 'Online' }, { label: 'Offline', value: 'Offline' }];
  const { selectedDevices, farms, selectedFarm } = useSelector((state) => state.appData);
  const [form, setForm] = useState(null);
  const [status, setStatus] = useState(options[0]);
  const [table, setTable] = useState([]);
  const { width } = useWindowSize();
  const [editItemId, setEditItemId] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const dispatch = useDispatch();
  const updateData = () => {
    getAllDevices()
      .then((res) => {
        if (res.status === 200) {
          dispatch(setDevices(res.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // console.log("Invalid Password");
        }
        if (error.response.status === 402) {
          // console.log(error.response.data);
        }
      });
  };
  const onChangeFarmHandle = (event) => {
    // console.log(event);
    dispatch(setDevicesBasedOnLocation(event?.label));

    dispatch(setSelectedFarm(event));
  }
  // const deviceStatus = useDeviceStatus();
  // console.log(deviceStatus)
  // useEffect(() => {
  //   devicesData?.forEach(device => {
  //     if (deviceStatus?.response === device.de) {
  //       console.log(deviceStatus.deviceStatus === 'active')
  //       device[3] =
  //         deviceStatus?.deviceStatus === "active" ? "Online" : "Offline"
  //       setUpdatedStatus(true);
  //     }
  //   })
  //   dispatch(setDevicesData(devicesData));
  // }, [deviceStatus])

  const onClickEditHandler = (i) => {
    setForm(getFormFieldsWithValue(DeviceFields(), selectedDevices[i]));
    setEditItemId(selectedDevices[i]?._id)
  }
  const onClickDeleteHandler = (i) => {
    setDeleteItemId(selectedDevices[i]?._id)
  }

  const headerData = ["SR. NO.", "Device ID", "Device  Group", "Device Status", "Data interval", "Actions"]

  let _devices = table.map(({ deviceId, location, interval, status }, index) => ({
    srno: index + 1, deviceId, location,
    status: status, interval,
    actions: <Actions onEdit={() => onClickEditHandler(index)} onDelete={() => onClickDeleteHandler(index)} />
  }))

  let _devicesDetails = table.map(({ deviceId, location, interval, status }, index) => ({
    "Device ID": deviceId, "Device  Group": location,
    "Device Status": status, "Data interval": interval
  }))

  const onClickAddhandler = () => {
    // console.log(DeviceFields())
    setForm(DeviceFields());

    // console.log(form)
  }

  useEffect(() => {
    setTable(selectedDevices.filter(d => status.value ? d.status === status.value : d));
  }, [selectedDevices]);


  const onCliCkStatusChange = (val) => {
    setStatus(val);
    setTable(selectedDevices.filter(d => val.value ? d.status === val.value : d));
  }




  return (

    <>
      {form && <Form form={form} setForm={setForm} updateData={updateData} submitFunction={editItemId ? editDevice : addNewDevice} title={editItemId ? 'Edit Device' : "Add New Device"} onClickClose={() => {
        setForm(null);
        setEditItemId(null);
      }} parentId={editItemId} />}

      {deleteItemId && (
        <DeleteHandler
          message={` Deleting this device will delete all of its data`}
          setDeleteBtn={() => setDeleteItemId(null)}
          title="Delete device"
          updateData={updateData}
          deleteFunction={deleteDevice}
          id={deleteItemId}
        />
      )}

      <div className={styles.Devices}>
        <div className={styles.ButtonWrap}>
          <div className={styles.Accordion}>
            <OptionsAccordion
              options={
                farms?.map(farm => {
                  return { label: farm, value: farm }
                })
              }
              value={selectedFarm}
              onChange={onChangeFarmHandle}
              text={"Farms"}
            />
            <OptionsAccordion text={"Status"} options={options} value={status} onChange={(val) => onCliCkStatusChange(val)} />
          </div>
          <Button className={styles.AddBtn} text="Add Device" type="device" onClick={() => onClickAddhandler()} width={width} />
          <Pages />
        </div>
        <Footer />
        <div style={{ height: '10px' }}></div>
        {width < 768
          ? <ItemsList items={_devicesDetails} onDelete={onClickDeleteHandler} onEdit={onClickEditHandler} />
          : <Table headerData={headerData} bodyData={_devices} />}

        {/* <Footer /> */}

      </div>
    </>
  );
};

Devices.propTypes = {};

Devices.defaultProps = {};

export default Devices;
