export const SET_USERS = 'SET_USERS';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE';
export const SET_SELECTED_TIME = 'SET_SELECTED_TIME';
export const SET_SENSORS_DATA = 'SET_SENSORS_DATA';
export const SET_ACTIVE_DEVICES = 'SET_ACTIVE_DEVICES';
export const SET_SELECTED_DEVICES = 'SET_SELECTED_DEVICES';
export const SET_FARMS = 'SET_FARMS';
export const SET_SELECTED_FARM = 'SET_SELECTED_FARM';
export const SET_JUMP = 'SET_JUMP';
export const SET_JUMP_TIME = 'SET_JUMP_TIME';