import React from "react";
import { useHistory } from "react-router";
import { Logout } from "store/reducers/AuthData";
import styles from "./Menu.module.scss";
import { useDispatch } from "react-redux";
import { logOut } from "store/actions/AuthData";
import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowSize";
import { useLocation } from "react-router";
import { MdListAlt } from "react-icons/md";
import { BiHome } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import { HiOutlineUsers } from "react-icons/hi";
import { TbDeviceTablet } from "react-icons/tb";
import { TbLogout } from "react-icons/tb";

const Menu = ({ data }) => {
  const location = useLocation();
  const history = useHistory();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { authData } = useSelector((state) => state.authData);

  const onClickHandler = (link) => {
    history.push(link);
  };
  const logout = () => {
    dispatch(logOut());
  };
  return (
    <>
      {width > 768 ? (
        <div className={styles.Header}>
          <div>
            <img
              src="/assets/icon/menu/logo1.png"
              style={{ cursor: "pointer", width: "75px", height: "74px" }}
            />
          </div>
          <div className={styles.OptionsWrap}>
            {data.map((item, index) => (
              <div key={index} onClick={() => onClickHandler(item.link)}>
                <div
                  style={{
                    color:
                      location.pathname === item.link
                        ? "#F85B4D"
                        : "#000000",
                  }}
                >
                  {item.icon}
                </div>

                <p
                  style={{
                    color:
                      location.pathname === item.link
                        ? "#F85B4D"
                        : "#000000",
                  }}
                  className={styles.Options}
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div
            className={styles.LogOutWrap}
            onClick={() => {
              logout();
            }}
          >
            <TbLogout size={25} />
            <p className={styles.LogOut}>Log out</p>
          </div>
        </div>
      ) : (
        <div className={styles.MobileMenu}>
          <div className={styles.Wrapper}>
            <div className={styles.OptionsWrap}>
              {data.map((item, index) => (

                <div
                  key={index}
                  onClick={() => onClickHandler(item.link)}
                >
                  <div
                    style={{
                      color:
                        location.pathname === item.link
                          ? "#F85B4D"
                          : "#000000",
                    }}
                  >
                    {item.icon}
                  </div>
                </div>

              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Menu.propTypes = {};

Menu.defaultProps = {};

export default Menu;
