import React, { useState } from "react";
import styles from "./Header.module.scss";
import PagesMenu from "../../NavMenu/CurserMenu/Pages";
import useWindowSize from "../../../hooks/useWindowSize";
import { setDevicesBasedOnLocation, setSelectedFarm } from "store/actions/AppData";
import { logOut } from "store/actions/AuthData";
import { TbLogout } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import OptionsAccordion from "components/OptionsAccordion/OptionsAccordion";
const Header = ({ heading }) => {
  const [showBtn, setShowBtn] = useState(false);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { authData } = useSelector(state => state.authData);

  const logout = () => {
    dispatch(logOut());
  };
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return (
    <div className={styles.Header}>
      <div className={styles.Wrapper}>
        {width < 768 ? (
          <TbLogout
            className={styles.LogOutWrap}
            onClick={() => {
              logout();
            }}
          />
        ) : null}
        <div className={styles.MainHeader}>
          <div className={styles.PageTitle}>
            <p className={styles.Title}>Smart poultry | {heading}</p>
            <p className={styles.Desc}>{new Date().getDate() + " " + monthNames[new Date().getMonth()] + " " + new Date().getFullYear()}</p>
          </div>
        </div>
        <div className={styles.UserWrap}>
          {width > 768 ? (
            <div className={styles.UserDetails}>
              <p className={styles.UserName}>{authData?.user?.name}</p>
              <p className={styles.Role}>{authData?.user?.isAdmin === true ? "Admin" : "Not Admin"}</p>
            </div>
          ) : null}

          <div className={styles.UserIcon}>
            <img src="/assets/icon/header/User.png" />
          </div>
        </div>
      </div>
    </div>
  );
};
Header.propTypes = {};

Header.defaultProps = {};

export default Header;

