import axios from "services/httpInstance/axios";
// import { deviceUrl } from "../url/devices";
import { addNewDeviceUrl, deviceUrl, deleteDeviceUrl, downloadUrl, getAllDatesUrl } from "services/url/devices";
import { allDevicesUrl, allValuesFromTimeUrl, refreshUrl } from "services/url/devices";


export const getAllDevices = (data) => {
    return axios.get(allDevicesUrl());
}

export const addNewDevice = (data) => {
    return axios.post(addNewDeviceUrl(), data);
}

export const editDevice = (data) => {

    return axios.put(`${deviceUrl(data?.id)}`, data?.data);
}

export const deleteDevice = (id) => {
    return axios.delete(`${deleteDeviceUrl(id)}`);
}

export const getAllValuesFromTime = (time, deviceId) => {
    return axios.post(allValuesFromTimeUrl(), { deviceId, time });
}

export const refreshData = (deviceId) => {
    return axios.post(refreshUrl(), { deviceId });
}

export const downloadData = (deviceId, from_date, to_date, type) => {
    return axios.post(downloadUrl(), { deviceId, from_date, to_date, type });
}

export const getAllDates = () => {
    return axios.get(getAllDatesUrl());
}