import LineChart from "components/LineChart/LineChart";
import React from "react";
import styles from "./ChartWrap.module.scss";

const ChartWrap = ({ values, param, labels, units, unit, data, ...rest }) => {


  // const chartWidth = 1000;
  // const smooth = ASAP(data, chartWidth);
  // console.log(units);
  // console.log({ smooth })

  return (
    <div
      // style={{ display: display ? 'block' : 'none' }}
      className={styles.MainWrap}>
      <div className={styles.HeaderWrap}>
        <p className={styles.Header}>{param}</p>
        <div className={styles.ValuesContainer}>
          <p className={styles.Value}>
            {values?.[values?.length - 1] || 0} {units}
          </p>
          <div className={styles.MinMaxContainer}>
            <p className={styles.MinMax}>Low : <span>{Math.min(...values)}</span>{" "}|</p>
            <p className={styles.MinMax} style={{ margin: "0 0 0 5px" }}>High : <span>{Math.max(...values)}</span></p>
          </div>
        </div>
      </div>
      <div className={styles.Chart}>
        <LineChart
          values={values}
          labels={labels}
          data={data} param={param} />
      </div>
    </div>
  );
};

ChartWrap.propTypes = {};

export default ChartWrap;
