import cloneDeep from 'lodash/cloneDeep';

export const getFormFieldsWithValue = (emptyFields, obj) => {
    let fields = cloneDeep(emptyFields);
    // console.log((fields, obj));

    fields.forEach((e, i, o) => {
        // console.log({ e, i, o })
        if (e.type !== 'group') {
            if (e.type === 'select') {
                e.value = e.options.filter(o => o.value === obj[e.id])[0]
            }
            else {
                e.value = obj[e.id];
            }
        } else {
            // console.log(e);
            o.splice(i, 1);
        }
        if (e.type === 'readonly') {
            o.splice(i, 1);
        }
    });
    return fields;
}