import React, { useState, useEffect } from "react";
import styles from "./Footer.module.scss";
import { useDispatch, useSelector } from "react-redux";
import useDeviceStatus from "hooks/useDeviceStatus";

const Footer = () => {
  const { devices, activeDevices, selectedDevices } = useSelector((state) => state.appData);


  return (
    <div className={styles.Container}>
      <div className={styles.Footer}>
        <p className={styles.Total}>
          Total devices : {selectedDevices?.length || 0}
        </p>
        <p className={styles.Active}>
          Active devices : {selectedDevices.filter(a => activeDevices.includes(a.deviceId))?.length || 0}
        </p>
      </div>
    </div>
  )
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
