import Data from 'pages/Data/Data'
import Devices from 'pages/Devices/Devices'
import Users from 'pages/Users/Users'
import Home from 'pages/Home/Home'
import React from 'react'
import { BiHome } from 'react-icons/bi'
import { HiOutlineUsers } from 'react-icons/hi'
import { MdListAlt } from 'react-icons/md'
import { TbDeviceTablet } from 'react-icons/tb'

const Routes = (props) => {
    return [
        {
            name: 'Home',
            link: '/dashboard/home',
            icon: <BiHome size={24} />,
            component: <Home {...props} />,
            enable: true,
            isMenu: true
        },
        {
            name: "Devices",
            link: "/dashboard/devices",
            icon: <TbDeviceTablet size={24} />,
            component: <Devices {...props} />,
            enable: true,
            isMenu: true

        },
        props ? {
            name: "Users",
            link: "/dashboard/users",
            icon: <HiOutlineUsers size={24} />,
            component: <Users {...props} />,
            enable: props?.isAdmin,
            isMenu: true
        } : {},
        {
            name: "Data",
            link: "/dashboard/data",
            icon: <MdListAlt size={24} />,
            component: <Data {...props} />,
            enable: true,
            isMenu: true
        },
    ]
}

export default Routes