import { addNewUserUrl, editUserUrl, deleteUserUrl } from "services/url/user";

import axios from "services/httpInstance/axios";

export const addNewUser = (data) => {
    return axios.post(addNewUserUrl(), data);
}

export const editUser = ({ id, data }) => {
    return axios.put(`${editUserUrl(id)}`, data);
}

export const deleteUser = (id) => {
    return axios.delete(`${deleteUserUrl(id)}`);
}