import React from "react";
import styles from "./Pages.module.scss";

const Pages = () => (
  <div className={styles.Wraper}>
    <div className={styles.MainContainer}>
      <div className={styles.PagesMenu}>
        <div className={styles.Page}>
          <p>1</p>
        </div>
        <div className={styles.Page}>
          <p>2</p>
        </div>
        <div className={styles.Page}>
          <p>3</p>
        </div>
        <div className={styles.PageIcon}>
          <img src="/assets/mobile-menu/Vector.jpg" />
        </div>
      </div>
    </div>
  </div>
);
export default Pages;
