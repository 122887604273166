import React from "react";
import { Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/Login/Login";
import DashboardLayout from "Layout/DashboardLayout/DashboardLayout";

type Props = {
 isAuth: Boolean;
 data: any;
};

const Routes = ({ isAuth, data }: Props) => {
 return (
  <>
   <PublicRoute isAuth={isAuth} exact path="/login">
    <Login />
   </PublicRoute>
   <PrivateRoute isAuth={isAuth} path="/dashboard">
    <DashboardLayout isAdmin={data?.user?.isAdmin} />
   </PrivateRoute>
   <PublicRoute isAuth={isAuth} exact path="/">
    <Redirect to="/login" />
   </PublicRoute>
   <PrivateRoute exact isAuth={isAuth} path="/dashboard">
    <Redirect to="/dashboard/home" />
   </PrivateRoute>
  </>
 );
};

export default Routes;
