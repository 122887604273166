import React from "react";
import styles from "./OptionsAccordion.module.scss";
import Select from "react-select";

const OptionsAccordion = ({ options, value, onChange, text, isFocused }) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#000000",
      "&:hover": {
        color: "#F85B4D",
      },
      backgroundColor: "#F5F5F5",
      border: 0,
      boxShadow: "none",
      cursor: "pointer",
      width: "100%",
      height: "36px"
    }),

    control: (provided) => ({
      ...provided,
      border: 0,
      boxShadow: "#F85B4D",
      backgroundColor: "#F5F5F5",
      width: "150px",
      cursor: "pointer",
      minHeight: "36px",
      // overflow: "hidden"
    }),

    dropdownIndicator: (styles, state) => ({
      ...styles,
      "&:hover": {
        color: "#F85B4D",
        backgroundColor: state.isSelected ? "black" : "",
        borderRadius: "3px",
      },
      color: "#000000",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#000000",
    }),
  };
  return (
    <div className={styles.Container}>
      <div className={styles.OptionsAccordion}>
        <p className={styles.LeftText}>{text}</p>
        <p className={styles.Devider}></p>
        <div className={styles.DropDown}>
          <Select
            styles={customStyles}
            // options={...}
            options={options}
            value={value}
            onChange={onChange}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
    </div>
  );
};

OptionsAccordion.propTypes = {};

OptionsAccordion.defaultProps = {};

export default OptionsAccordion;
