import React from 'react';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import Header from './Header/Header';
import Body from './Body/Body';

const Table = ({ headerData, bodyData, actions }) => {
  return (
    <div className={styles.Table}>
      <Header data={headerData} />
      <Body data={bodyData} />
    </div>
  )
};

Table.propTypes = {};

Table.defaultProps = {};

export default Table;
