import React from 'react';
import PropTypes from 'prop-types';
import styles from './Row.module.scss';
import { IonCol, IonGrid, IonRow } from '@ionic/react';

const Row = ({ data }) => (
  <div className={styles.Row}>
    <IonGrid fixed={true}>
      <IonRow >
        {
          Object.values(data)?.map((item, index) => (
            <IonCol style={{ lineBreak: "anywhere" }} key={index}>{item}</IonCol>
          ))
        }
      </IonRow>
    </IonGrid>
  </div>
);

Row.propTypes = {};

Row.defaultProps = {};

export default Row;
